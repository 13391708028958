import { getLeafNodes } from "./viewerObjectTreeUtils"

export class ViewerModelSelectionLock {
    private readonly viewer: Autodesk.Viewing.Viewer3D;
    private readonly model: Autodesk.Viewing.Model;
    private readonly dbIds: number[];
    private isLocked: boolean;

    constructor(viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model, dbIds: number[]) {
        this.viewer = viewer;
        this.model = model;
        this.dbIds = dbIds;
        this.isLocked = false;
    }

    lock() {
        if (this.isLocked)
            return;

        this.viewer.lockSelection(this.dbIds, true, this.model);
        this.isLocked = true;
    }

    unlock() {
        if (!this.isLocked)
            return;

        this.viewer.lockSelection(this.dbIds, false, this.model);
        this.isLocked = false;
    }
}

export const createModelSelectionLock = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => async () => {
    const leafNodes = await getLeafNodes(model);

    return new ViewerModelSelectionLock(viewer, model, leafNodes);
}