export type ExternalIdsMapping = {
    [key: string]: number;
}

export class ViewerModelUtils {
    static isMetric(model: Autodesk.Viewing.Model): boolean {
        const displayUnit = model.getDisplayUnit();

        return displayUnit === "mm" || displayUnit === "cm" || displayUnit === "m";
    }

    static getExternalIdsMapping(model: Autodesk.Viewing.Model): Promise<ExternalIdsMapping> {
        return new Promise<ExternalIdsMapping>((resolve, reject) => model.getExternalIdMapping(resolve, reject));
    }
}