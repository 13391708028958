import { Code } from "react-feather";
import { colors } from "../../config";
import { InsulatedGlassUnitValuesPreferences } from "../forge/insulatedGlassUnit";
import { ProjectProperty } from "../projectProperty";
import { InsulatedGlassUnitProfileType } from "@dextall/windows-builder";

type Props = {
    preferences: InsulatedGlassUnitValuesPreferences;
    onChanged: (newPreferences: InsulatedGlassUnitValuesPreferences) => void;
}

export const IguWindowProfileSwitcher = ({ preferences, onChanged }: Props) => {
    return <ProjectProperty header={{ title: "Profile type", children: <Code size={12} stroke={colors.subheading} /> }}
        preview={<div style={{ display: "flex", flexDirection: "row" }}>
            <input type="radio" checked={preferences.profileType === InsulatedGlassUnitProfileType.Aw}
                onChange={() => onChanged({ ...preferences, profileType: InsulatedGlassUnitProfileType.Aw })}
                style={{ marginLeft: 0, cursor: "pointer" }} />AW
            <input type="radio" checked={preferences.profileType === InsulatedGlassUnitProfileType.Cw}
                onChange={() => onChanged({ ...preferences, profileType: InsulatedGlassUnitProfileType.Cw })}
                style={{ marginLeft: 14, cursor: "pointer" }} />CW
        </div>} />
}