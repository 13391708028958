import React from "react"
import { colors } from "../config/colors";

const folderContainer: React.CSSProperties = {
    paddingTop: "2px",
    paddingRight: "2px",
    paddingLeft: "2px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
};

const folder: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    borderRadius: "24px 0 24px 24px",
    backgroundColor: colors.white,
    padding: 20
};

const modelView: React.CSSProperties = {
    position: "relative",
    flex: 1,
    backgroundColor: "#EBF3FF",
    height: "100%",
    borderRadius: 18,
};

const fullscreen: React.CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100vh",
    top: 0,
    left: 0,
    borderRadius: 0,
    zIndex: 1000,
};

const fullscreenButton: React.CSSProperties = {
    backgroundColor: colors.fullscreen,
    position: "absolute",
    top: 2,
    left: 2,
    padding: 8,
    zIndex: 5
};

const folderTopContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
};

const folderTop: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    paddingTop: 10,
    paddingLeft: 0,
    paddingRight: 20,
    height: 50,
    borderRadius: "24px 24px 0 0",
    backgroundColor: colors.white,
    alignSelf: "flex-end",
};

const modelFolderTop: React.CSSProperties = {
    flex: 1,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 20,
    height: 50,
    borderRadius: "24px 24px 0 0",
    backgroundColor: colors.white,
    alignSelf: "flex-end",
};

const topTextContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 15
};

const projectInfo: React.CSSProperties = {
    paddingRight: 15,
    paddingLeft: 15,
    overflow: "hidden scroll",
    minWidth: 360,
};

export const styles = {
    folderContainer,
    folder,
    modelView,
    fullscreen,
    fullscreenButton,
    folderTopContainer,
    folderTop,
    modelFolderTop,
    topTextContainer,
    projectInfo
};
