import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import { AdminPanelTypesApp } from "./AdminPanelTypesApp";
import { State, getCurrentProject, getSelectedModelVersion, isAnalyticsTabVisible, isHighLodModelViewMode, mainReducer } from './reducers/mainReducer';
import { getLocalApplicationMetadata } from './appMetadata';
import { appRootId } from "./components/shared";
import App from './App';
import ProfileLoader from './ProfileLoader';
import ConnectedAdminUsersApp from './ConnectedAdminUsersApp';
import "@weave-design/fonts/build/ArtifaktElement.css";
import "./index.css";

const appMetadata = getLocalApplicationMetadata();

if (appMetadata.environment === "production")
    Sentry.init({
        dsn: "https://1573117b533ddaac403e496f5f1abf66@o4506003617808384.ingest.sentry.io/4506019362242560",
        environment: "webapp",
        defaultIntegrations: false,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: `dextall-studio-frontend@${appMetadata.version}`,
        integrations: [
            Sentry.breadcrumbsIntegration({ console: false }),
            Sentry.globalHandlersIntegration(),
            Sentry.dedupeIntegration(),
            Sentry.functionToStringIntegration(),
            Sentry.replayIntegration({ mutationLimit: 500 }),
            Sentry.captureConsoleIntegration({ levels: ["warn", "error"] })],
        ignoreErrors: [
            /Attempting to load version \'51\' of Google Charts\, but the previously loaded \'current\' will be used instead\./
        ]
    });

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state: State) => {
        const selectedProject = getCurrentProject(state);
        const selectedModel = getSelectedModelVersion(state);
        const isHighLodMode = isHighLodModelViewMode(state);
        const isAnalyticsVisible = isAnalyticsTabVisible(state);

        return {
            selectedProject: selectedProject ? { id: selectedProject.id, name: selectedProject.name } : null,
            selectedModel: selectedModel ? { id: selectedModel.id, name: selectedModel.fileName, version: selectedModel.versionNumber } : null,
            isHighLodMode,
            isAnalyticsVisible
        };
    },

    actionTransformer: (action) => {
        return action.type === "ADD_LOG" || action.type === "ADD_ERROR" ? action : null;
    }
});

const rootElement = document.getElementById(appRootId)!;

const root = createRoot(rootElement);

root.render(
    <Provider store={configureStore({ reducer: mainReducer, enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer) })}>
        <Router>
            <ProfileLoader>
                <Routes>
                    <Route path='/' element={<App applicationType="ar" />} />
                    <Route path='/panel-types-admin-catalog' element={<AdminPanelTypesApp />} />
                    <Route path='/admin' element={<ConnectedAdminUsersApp />} />
                    <Route path='/engineering' element={<App applicationType="en" />} />
                    <Route path='/bid' element={<App applicationType="bid" />} />
                </Routes>
            </ProfileLoader>
        </Router>
    </Provider>
);