import { SaveActionType } from "../actions/modelSaveCycleActions";

export type State = {
    isSaving: boolean;
    failureMessage: string | null;
};

export const initialSaveState: State = {
    isSaving: false,
    failureMessage: null,
};

export const getIsSaving = (state: State) => state.isSaving;

export const getModelCycleFailureMessage = (state: State) => state.failureMessage;

export default function saveReducer(state: State = initialSaveState, action: SaveActionType) {
    switch (action.type) {
        case "SET_IS_SAVING": {
            return { ...state, isSaving: action.isSaving };
        }
        case "MODEL_SAVE_CYCLE_FAILURE": {
            return { ...state, failureMessage: action.message };
        }
        default:
            return state;
    }
}
