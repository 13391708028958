export type SetSavingStateType = {
    type: "SET_IS_SAVING";
    isSaving: boolean;
};

export type SetModelSaveCycleFailureMessage = {
    type: "MODEL_SAVE_CYCLE_FAILURE";
    message: string | null;
}

export type SaveActionType = SetSavingStateType | SetModelSaveCycleFailureMessage;

export const setSaving = (isSaving: boolean): SetSavingStateType => ({
    type: "SET_IS_SAVING",
    isSaving
});

export const setModelCycleError = (message: string | null): SetModelSaveCycleFailureMessage => ({
    type: "MODEL_SAVE_CYCLE_FAILURE",
    message
});