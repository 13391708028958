import { CornerEditor } from './forge/cornerEditor';
import { PanelEditor } from './forge/panelEditor';
import { WallWindow } from './forge/wallWindow';
import { WorkerAction, WorkerResultMessage } from './forge/workers/modelSaveWorker';
import { EventDispatcher } from './forge/eventBus/eventDispatcher';
import repo from '../Repository';

export class ModelSaveCycle {
    private readonly worker: Worker;
    private eventBus: EventDispatcher | undefined;
    private queuedTasksCount = 0;

    constructor(private readonly modelId: string) {
        this.worker = new Worker(new URL('./forge/workers/modelSaveWorker.ts', import.meta.url), { type: 'module' });

        this.worker.onmessage = (event) => this.onWorkerMessage(event);
    }

    start(eventBus: EventDispatcher) {
        this.eventBus = eventBus;

        const savedToken = repo.getAccessToken();
        if (!savedToken)
            return;

        this.postMessage({
            action: 'setAccessToken',
            token: savedToken,
            modelId: this.modelId
        });
    }

    addPanel(panel: PanelEditor) {
        this.onQueueSave();
        this.postMessage({ action: 'addPanel', item: panel.serialize() });
    }

    addCorner(corner: CornerEditor) {
        this.onQueueSave();
        this.postMessage({ action: 'addCorner', item: corner.serialize() });
    }

    addWindow(wallWindow: WallWindow) {
        this.onQueueSave();
        this.postMessage({ action: 'addWindow', item: wallWindow.serialize() });
    }

    updatePanel(panel: PanelEditor) {
        this.onQueueSave();
        this.postMessage({ action: 'updatePanel', item: panel.serialize() });
    }

    updateCorner(corner: CornerEditor) {
        this.onQueueSave();
        this.postMessage({ action: 'updateCorner', item: corner.serialize() });
    }

    updateWallWindow(wallWindow: WallWindow) {
        this.onQueueSave();
        this.postMessage({ action: 'updateWindow', item: wallWindow.serialize() });
    }

    removePanel(panel: PanelEditor) {
        this.onQueueSave();
        this.postMessage({ action: 'removePanel', itemId: panel.panel.id });
    }

    removeCorner(corner: CornerEditor) {
        this.onQueueSave();
        this.postMessage({ action: 'removeCorner', itemId: corner.corner.id });
    }

    removeWindow(windowId: string) {
        this.onQueueSave();
        this.postMessage({ action: 'removeWindow', itemId: windowId });
    }

    requestStop() {
        this.eventBus = undefined;
        this.postMessage({ action: 'requestStop' });
    }

    getQueuedTasksCount(): number {
        return this.queuedTasksCount;
    }

    private postMessage(message: WorkerAction) {
        this.worker.postMessage(message);
    }

    private onWorkerMessage(event: MessageEvent<WorkerResultMessage>) {
        const data = event.data;

        switch (data.action) {
            case "flushed":
                this.queuedTasksCount = data.taskQueueCount;
                if (this.queuedTasksCount === 0)
                    this.eventBus?.dispatchEvent({ type: "Dextall.ModelSaveCycle.FlushedAll", payload: null });

                break;

            case "error":
                this.eventBus?.dispatchEvent({ type: "Dextall.ModelSaveCycle.Error", payload: data.message });
                break;

            case "stopped":
                this.worker.terminate();
                break;
        }
    }

    private onQueueSave() {
        ++this.queuedTasksCount;

        this.eventBus?.dispatchEvent({ type: "Dextall.ModelSaveCycle.ItemQueued", payload: null });
    }
}
