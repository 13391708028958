import React from "react"
import { Engine } from "../assets/icons/engine";
import { dWallColor, retrofitColor, snowmanColor } from "../assets/panelSystemTypeStyles";
import { SystemType } from "./project";
import { ProjectProperty } from "./projectProperty";
import { ProjectSystemType, ProjectSystemTypeProps } from "./projectSystemType";

type Props = {
    value: SystemType;
    setValue: (systemType: SystemType) => void;
    isEditMode: boolean
}

export const ProjectSystemEditorField = ({ value, setValue, isEditMode }: Props) => {
    type SystemTypeOption = Omit<ProjectSystemTypeProps, "isEditable"> & { type: SystemType, selectable: boolean };

    const options: SystemTypeOption[] = [{
        title: {
            full: "DWall [obsolete]",
            short: "D"
        },
        color: dWallColor,
        check: () => setValue(SystemType.DWall),
        checked: value === SystemType.DWall,
        type: SystemType.DWall,
        selectable: false
    }, {
        title: {
            full: "SnowMan",
            short: "S"
        },
        color: snowmanColor,
        check: () => setValue(SystemType.Snowman),
        checked: value === SystemType.Snowman,
        type: SystemType.Snowman,
        selectable: true
    }, {
        title: {
            full: "Retrofit [upcoming]",
            short: "R"
        },
        color: retrofitColor,
        check: () => setValue(SystemType.Retrofit),
        checked: value === SystemType.Retrofit,
        type: SystemType.Retrofit,
        selectable: false
    }];

    const selectedOption = options.find(x => x.type === value)!;

    return (<ProjectProperty
        header={{ title: "System", children: <Engine /> }}
        isEditMode={isEditMode}
        preview={<ProjectSystemType {...selectedOption} isEditable={false} />}
        edit={<>{options.map((x, i) => <ProjectSystemType {...x} isEditable={true} key={`system-type-${i}`} />)}</>}
    />)
}