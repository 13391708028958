import { makeFixOnObjectRecreation } from "./viewer-utils/modelBuilderObjectRecreationFix";
import { ExtrusionGeometry, ExtrusionGeometryRegistration } from "./claddingCellExtrusionGeometryFactory";
import { RTree } from "@dextall/rtree";

type SpatialCacheItem = {
    size: THREE.Vector3;
    geomId?: number;
    numFragments: number;
}

type GeomsCountCache = {
    [geomId: number]: number | undefined;
}

type GeometryType = ExtrusionGeometry['type'];

type GeomTypeCache = {
    [geomId: number]: GeometryType | undefined;
}

type NamedGeometryGroupCache = {
    [geomId: number]: string | undefined;
}

const itemSize = 1e-3;

export class ModelBufferedGeometry {
    private readonly simpleGeometrySpatialTree = new RTree<SpatialCacheItem>();
    private readonly namedGeometrySpatialTrees = new Map<string, RTree<SpatialCacheItem>>();
    private readonly geomsCountCache: GeomsCountCache = {};
    private readonly geomsTypeCache: GeomTypeCache = {};
    private readonly geomsGroupsCache: NamedGeometryGroupCache = {};

    constructor(
        private readonly viewer: Autodesk.Viewing.Viewer3D,
        private readonly modelBuilder: Autodesk.Viewing.ModelBuilder) {

    }

    get model(): Autodesk.Viewing.Model {
        return this.modelBuilder.model;
    }

    add(geometry: ExtrusionGeometry) {
        const geomId = this.getOrCreateGeometry(geometry);

        this.geomsCountCache[geomId] = (this.geomsCountCache[geomId] || 0) + 1;
        this.geomsTypeCache[geomId] = geometry.type;
        this.geomsGroupsCache[geomId] = geometry.type === "named-shape" ? geometry.name : undefined;

        return this.modelBuilder.addFragment(geomId, geometry.material, geometry.matrix);
    }

    register(registration: ExtrusionGeometryRegistration | ExtrusionGeometryRegistration[]) {
        const geometries = Array.isArray(registration) ? registration : [registration];

        for (const geometry of geometries)
            switch (geometry.type) {
                case "box":
                    this.registerSimpleBoxGeometry(geometry.size);
                    break;

                case "named-shape":
                    this.registerNamedGeometry(geometry.size, geometry.name);
                    break;
            }
    }

    remove(fragmentId: number) {
        const geometryId = this.modelBuilder.fragList.getGeometryId(fragmentId)!;

        this.modelBuilder.removeFragment(fragmentId);

        --this.geomsCountCache[geometryId]!;

        if (this.geomsTypeCache[geometryId] === "box" && this.geomsCountCache[geometryId] === 0)
            this.simpleGeometrySpatialTree.removeWhere(x => x?.geomId === geometryId);

        if (this.geomsTypeCache[geometryId] === "named-shape" && this.geomsCountCache[geometryId] === 0)
            this.namedGeometrySpatialTrees.get(this.geomsGroupsCache[geometryId]!)?.removeWhere(x => x?.geomId === geometryId);

        if (this.geomsCountCache[geometryId] === 0) {
            const geometry = this.modelBuilder.geomList.getGeometry(geometryId)!;
            this.modelBuilder.removeGeometry(geometryId);
            geometry.dispose();
        }
    }

    removeFragments(fragIds: number[]) {
        for (const fragId of fragIds)
            this.remove(fragId);
    }

    changeFragmentsDbId(fragments: number | number[], dbId: number) {
        this.modelBuilder.changeFragmentsDbId(fragments, dbId);
    }

    makeFixOnObjectRecreation() {
        makeFixOnObjectRecreation(this.modelBuilder);
    }

    statistics() {
        let boxedGeometriesCount = 0;
        let namedShapedGeometriesCount = 0;
        let shapedGeometriesCount = 0;

        for (const geomId in this.geomsTypeCache) {
            if (this.geomsTypeCache[geomId] === "box")
                ++boxedGeometriesCount;
            else if (this.geomsTypeCache[geomId] === "named-shape")
                ++namedShapedGeometriesCount;
            else
                ++shapedGeometriesCount;
        }

        return { boxedGeometriesCount, namedShapedGeometriesCount, shapedGeometriesCount, total: boxedGeometriesCount + shapedGeometriesCount + namedShapedGeometriesCount };
    }

    packNormals(geometry: THREE.BufferGeometry): THREE.BufferGeometry {
        return this.modelBuilder.packNormals(geometry);
    }

    dispose() {
        // @ts-ignore
        delete this.modelBuilder;
        // @ts-ignore
        delete this.simpleGeometrySpatialTree;
        // @ts-ignore
        delete this.namedGeometrySpatialTrees;
        // @ts-ignore
        delete this.geomsCountCache;
        // @ts-ignore
        delete this.geomsTypeCache;
        // @ts-ignore
        delete this.geomsGroupsCache;
        // @ts-ignore
        delete this.viewer;
    }

    private getOrCreateGeometry(geometry: ExtrusionGeometry): number {
        switch (geometry.type) {
            case "box":
                return this.getOrCreateSimpleBoxGeometry(geometry.size, geometry.geometry);

            case "named-shape":
                return this.getOrCreateNamedGeometry(geometry.size, geometry.geometry, geometry.name);

            default:
                return this.modelBuilder.addGeometry(geometry.geometry);
        }
    }

    private getOrCreateSimpleBoxGeometry(size: THREE.Vector3, geometry: THREE.BufferGeometry): number {
        const existingGeometry = findExistingGeometryItem(this.simpleGeometrySpatialTree, size);

        if (existingGeometry) {
            if (!existingGeometry.geomId)
                existingGeometry.geomId = this.modelBuilder.addGeometry(geometry, existingGeometry.numFragments);

            return existingGeometry.geomId;
        }

        const geomId = this.modelBuilder.addGeometry(geometry);

        addCachedGeometry(this.simpleGeometrySpatialTree, size, geomId);

        return geomId;
    }

    private registerSimpleBoxGeometry(size: THREE.Vector3) {
        const existingGeometry = findExistingGeometryItem(this.simpleGeometrySpatialTree, size);

        if (existingGeometry)
            ++existingGeometry.numFragments;
        else
            addCachedGeometry(this.simpleGeometrySpatialTree, size, undefined);
    }

    private getOrCreateNamedGeometry(size: THREE.Vector3, geometry: THREE.BufferGeometry, name: string): number {
        const spatialTree = this.namedGeometrySpatialTrees.get(name) || new RTree<SpatialCacheItem>();

        this.namedGeometrySpatialTrees.set(name, spatialTree);

        const existingGeometry = findExistingGeometryItem(spatialTree, size);

        if (existingGeometry) {
            if (!existingGeometry.geomId)
                existingGeometry.geomId = this.modelBuilder.addGeometry(geometry, existingGeometry.numFragments);

            return existingGeometry.geomId;
        }

        const geomId = this.modelBuilder.addGeometry(geometry);

        addCachedGeometry(spatialTree, size, geomId);

        return geomId;
    }

    private registerNamedGeometry(size: THREE.Vector3, name: string) {
        const spatialTree = this.namedGeometrySpatialTrees.get(name) || new RTree<SpatialCacheItem>();

        this.namedGeometrySpatialTrees.set(name, spatialTree);

        const existingGeometry = findExistingGeometryItem(spatialTree, size);

        if (existingGeometry)
            ++existingGeometry.numFragments;
        else
            addCachedGeometry(spatialTree, size, undefined);
    }
}

const addCachedGeometry = (geometrySpatialTree: RTree<SpatialCacheItem>, dimensions: THREE.Vector3, geomId?: number) => {
    geometrySpatialTree.insert({
        minX: dimensions.x - 0.5 * itemSize,
        minY: dimensions.y - 0.5 * itemSize,
        maxX: dimensions.x + 0.5 * itemSize,
        maxY: dimensions.y + 0.5 * itemSize,
        payload: { size: dimensions, geomId, numFragments: 1 }
    });
}

const findExistingGeometryItem = (geometrySpatialTree: RTree<SpatialCacheItem>, dimensions: THREE.Vector3): SpatialCacheItem | undefined => {
    return geometrySpatialTree.search({
        minX: dimensions.x - 0.5 * itemSize,
        minY: dimensions.y - 0.5 * itemSize,
        maxX: dimensions.x + 0.5 * itemSize,
        maxY: dimensions.y + 0.5 * itemSize
    }).find(x => x.payload!.size.distanceTo(dimensions) < 0.5 * itemSize)?.payload;
}