import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateProject, cancelEditingCurrentProject, saveCurrentProject, currentProjectSettings, currentProjectInfo, editCurrentProject } from "../actions/projectsActions";
import { areUnreleasedFeaturesAvailable, getCurrentProject, getInsulatedGlassUnits, isEngineeringModelLoading, isBidManager, isCurrentProjectInEditMode, isCurrentProjectInInfoMode, isCurrentProjectInSettingsMode, isModelLoading, State } from "../reducers/mainReducer";
import { styles } from "../assets/studioPaneStyles";
import { InputText } from "./inputText";
import { TagH4ExternalLink, TextBody2, TextH3 } from "../assets/text/index";
import { ProjectEditorField } from "./projectEditorField";
import { LocationValue, ProjectEditorAutocompleteField } from "./projectEditorAutocompleteField";
import { CalendarEditorField } from "./calendarEditorField";
import { ContentNotReady } from "./contentNotReady";
import { colors } from "../config/colors";
import { Home, MapPin, User, MessageSquare, Layout, Percent, Edit, X } from "react-feather";
import { AppButton } from "./appButton";
import { IProject } from './project';
import { ProjectSystemEditorField } from './projectSystemEditorField';
import { IInsulatedGlassUnit, InsulatedGlassUnitValuesPreferences } from './forge/insulatedGlassUnit';
import { ProjectWindowsIGUPreferencesDialog } from './projectWindowsIGUPreferencesDialog';
import { IguTitle } from './igu/iguTitle';
import { ApplicationType } from '../applicationType';
import { useButtonState } from './useButtonState';
import ModelsNavigationBar from './modelsNavigationBar';
import EngineeringProductionModelsNavigationBar from './engineeringProductionModelsNavigationBar';
import { AppLoadingSpinner } from './appLoadingSpinner';
import { Dialog } from '@mui/material';
import repo from "../Repository";

interface IComponentProps {
    project: IProject | null;
    isEditMode: boolean;
    isBetaUser: boolean;
    isBidManager: boolean;
    insulatedGlassUnits: IInsulatedGlassUnit[];
    applicationType: ApplicationType;
    showInfo: boolean;
    showSettings: boolean;
    modelsLoading: boolean;
    engineeringModelsLoading: boolean;

    updateProject: (project: IProject) => void;
    saveCurrentProject: (applicationType: ApplicationType) => void;
    editCurrentProject: (mode: boolean) => void;
    cancelEditingCurrentProject: (applicationType: ApplicationType) => void;
    currentProjectInfo: (mode: boolean) => void;
    currentProjectSettings: (mode: boolean) => void;
}

export const StudioProjectPane = (props: IComponentProps) => {
    const editButtonState = useButtonState();

    const [windowsIGUPreferencesVisible, setWindowsIGUPreferencesVisible] = useState(false);
    const componentsLibraryUrl = `https://engineering.dextall.com/library?r=${Date.now().toString()}#${repo.getAccessToken()}`;

    const onSetAddress = (address: string, geoLocation: LocationValue | null) => {
        const location = geoLocation?.location || props.project!.location;
        const postalCode = geoLocation?.postalCode || props.project!.postalCode;

        props.updateProject({ ...props.project!, address, location, postalCode });
    }

    const queryGeoLocation = async (address: string) => {
        const response = await repo.autocompleteAddress(address);

        return response.item;
    }

    const confirmWindowIgu = (selectedInsulationGlassUnitId: string | null, insulatedGlassUnitValuesPreferences: InsulatedGlassUnitValuesPreferences | null) => {
        const selectedInsulatedGlassUnit = props.insulatedGlassUnits.find(x => x.id === selectedInsulationGlassUnitId) || null;

        props.updateProject({ ...props.project!, insulatedGlassUnitValuesPreferences, selectedInsulatedGlassUnit });

        setWindowsIGUPreferencesVisible(false);
    }

    useEffect(() => {
        if (props.isEditMode) {
            editButtonState.handleMouseLeave();
            editButtonState.handleMouseUp();
        }
    }, [props.isEditMode, editButtonState]);

    return (<>
        {(props.modelsLoading || props.engineeringModelsLoading) && <AppLoadingSpinner />}
        {!props.project && <div style={styles.folder}><ContentNotReady /></div>}
        {props.project && <div style={styles.folder}>
            {(!props.modelsLoading && !props.engineeringModelsLoading) &&
                <div style={{ overflowY: "scroll", width: "100%", maxHeight: "calc(100vh - 160px)" }}>
                    <ModelsNavigationBar isEngineer={props.applicationType === "en"} isBid={props.applicationType === "bid"} applicationType={props.applicationType} />
                    {props.applicationType == "en" && (
                        <div>
                            <EngineeringProductionModelsNavigationBar />
                            <TagH4ExternalLink title="Custom components" url={componentsLibraryUrl} />
                        </div>
                    )}
                </div>
            }
            <Dialog open={props.showInfo}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 30,
                        paddingRight: props.isEditMode ? 70 : 40
                    }}>
                    <div style={styles.projectInfo}>

                        <TextH3 style={{ textAlign: "center", marginBottom: 13 }}>{props.project.name} information</TextH3>

                        {!props.isEditMode &&
                            <AppButton
                                style={{ backgroundColor: "#FFCACA", padding: "7px", marginLeft: 8, marginRight: 8, position: "absolute", top: 23, right: 5 }}
                                onClick={() => props.currentProjectInfo(false)}
                            >
                                <X size={18} stroke={"#C93636"} />
                            </AppButton>
                        }
                        {props.isEditMode && <>
                            <AppButton
                                style={{ backgroundColor: "#E2FFDD", padding: "7px", marginLeft: 8, color: "#2D861E", fontSize: "13px", position: "absolute", top: 25, right: 50 }}
                                onClick={() => props.saveCurrentProject(props.applicationType)}>Save</AppButton>
                            <AppButton
                                style={{ backgroundColor: "#FFCACA", padding: "7px", marginRight: 3, position: "absolute", top: 25, right: 5 }}
                                onClick={() => props.cancelEditingCurrentProject(props.applicationType)}>
                                <X size={18} stroke={"#C93636"} />
                            </AppButton>
                        </>}

                        {props.isEditMode && (
                            <>
                                <TextBody2 style={{ color: colors.subheading }}>Project name</TextBody2>
                                <InputText value={props.project.name} style={{ marginBottom: 22 }} setValue={x => props.updateProject({ ...props.project!, name: x })} />
                            </>
                        )}

                        <ProjectEditorField value={props.project.author} setValue={x => props.updateProject({ ...props.project!, author: x })}
                            title="Author" isEditMode={props.isEditMode}>
                            <User size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorField>

                        <ProjectEditorField value={props.project.organizationName} setValue={x => props.updateProject({ ...props.project!, organizationName: x })}
                            title="Organization" isEditMode={props.isEditMode}>
                            <Home size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorField>

                        <ProjectEditorField value={props.project.buildingName} setValue={x => props.updateProject({ ...props.project!, buildingName: x })}
                            title="Building name" isEditMode={props.isEditMode}>
                            <Home size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorField>

                        <CalendarEditorField value={props.project.issueDate} setValue={x => props.updateProject({ ...props.project!, issueDate: x instanceof Date ? x.toISOString() : x })}
                            title="Issue Date" isEditMode={props.isEditMode} />

                        <ProjectEditorField value={props.project.clientName} setValue={x => props.updateProject({ ...props.project!, clientName: x })}
                            title="Client" isEditMode={props.isEditMode}>
                            <User size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorField>

                        <ProjectEditorField value={props.project.postalCode}
                            setValue={x => props.updateProject({ ...props.project!, postalCode: x })}
                            title="Postal Code" isEditMode={props.isEditMode}>
                            <MapPin size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorField>

                        <ProjectEditorAutocompleteField
                            value={{ postalCode: props.project.postalCode, address: props.project.address, location: props.project.location }}
                            getLabel={x => x?.address}
                            setValue={(address, geoLocation) => onSetAddress(address, geoLocation)}
                            queryAutocompleteItem={queryGeoLocation}
                            isEditMode={props.isEditMode}
                            title="Address">
                            <MapPin size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorAutocompleteField>

                        <ProjectEditorField value={props.project.description} setValue={x => props.updateProject({ ...props.project!, description: x })}
                            title="Description" isEditMode={props.isEditMode} multiline={true}>
                            <MessageSquare size={12} stroke={colors.subheading} strokeWidth={3} />
                        </ProjectEditorField>

                        {!props.isEditMode && props.applicationType === "ar" &&
                            <AppButton
                                style={{ backgroundColor: colors.folderTopButton, padding: "10px", marginLeft: "8px", position: "absolute", bottom: "25px", right: "10px" }}
                                onClick={() => props.editCurrentProject(true)}
                                colored={true}
                                hovered={editButtonState.isHovered}
                                handleMouseEnter={editButtonState.handleMouseEnter}
                                handleMouseLeave={editButtonState.handleMouseLeave}
                                selected={editButtonState.isSelected}
                                handleMouseDown={editButtonState.handleMouseDown}
                                handleMouseUp={editButtonState.handleMouseUp}
                            >
                                <Edit size={20} style={{ stroke: editButtonState.isSelected ? colors.textBlack : editButtonState.isHovered ? colors.hovered : colors.subheading }} />
                            </AppButton>}
                    </div>
                </div>
            </Dialog>
            <Dialog open={props.showSettings} >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 30,
                        paddingRight: props.isEditMode ? 70 : 40
                    }}>
                    <div style={styles.projectInfo}>

                        <TextH3 style={{ textAlign: "center", marginBottom: 13 }}>{props.project.name} settings</TextH3>

                        {!props.isEditMode &&
                            <AppButton
                                style={{ backgroundColor: "#FFCACA", padding: "7px", marginLeft: 8, marginRight: 8, position: "absolute", top: 23, right: 5 }}
                                onClick={() => props.currentProjectSettings(false)}
                            >
                                <X size={18} stroke={"#C93636"} />
                            </AppButton>
                        }

                        {props.isEditMode && <>
                            <AppButton
                                style={{ backgroundColor: "#E2FFDD", padding: "7px", marginLeft: 8, color: "#2D861E", fontSize: "13px", position: "absolute", top: 25, right: 50 }}
                                onClick={() => props.saveCurrentProject(props.applicationType)}>Save</AppButton>
                            <AppButton
                                style={{ backgroundColor: "#FFCACA", padding: "7px", marginRight: 3, position: "absolute", top: 25, right: 5 }}
                                onClick={() => props.cancelEditingCurrentProject(props.applicationType)}>
                                <X size={18} stroke={"#C93636"} />
                            </AppButton>
                        </>}

                        {props.isBidManager && <>
                            <ProjectEditorField value={props.project.margin.toString()}
                                setValue={x => props.updateProject({ ...props.project!, margin: parseFloat(x) })}
                                title="Margin" type="number" isEditMode={props.isEditMode}>
                                <Percent size={12} stroke={colors.subheading} strokeWidth={3} />
                            </ProjectEditorField>

                            <ProjectEditorField value={props.project.taxRate.toString()}
                                setValue={x => props.updateProject({ ...props.project!, taxRate: parseFloat(x) })}
                                title="Tax Rate" type="number" isEditMode={props.isEditMode}>
                                <Percent size={12} stroke={colors.subheading} strokeWidth={3} />
                            </ProjectEditorField>

                            <ProjectEditorField value={props.project.duties.toString()}
                                setValue={x => props.updateProject({ ...props.project!, duties: parseFloat(x) })}
                                title="Duties" type="number" isEditMode={props.isEditMode}>
                                <Percent size={12} stroke={colors.subheading} strokeWidth={3} />
                            </ProjectEditorField>
                        </>}

                        <ProjectSystemEditorField value={props.project.systemType}
                            setValue={x => props.updateProject({ ...props.project!, systemType: x })}
                            isEditMode={props.isEditMode} />

                        {props.isBetaUser && <ProjectEditorField
                            value={<IguTitle insulatedGlassUnit={props.project.selectedInsulatedGlassUnit} editable={false} />}
                            title="Windows IGU"
                            isEditMode={props.isEditMode}
                            setValue={() => { }}
                            edit={<IguTitle insulatedGlassUnit={props.project.selectedInsulatedGlassUnit} editable={true} onEditRequested={() => setWindowsIGUPreferencesVisible(true)} />}>
                            <Layout size={12} stroke={colors.subheading} />
                        </ProjectEditorField>}

                        {!props.isEditMode && props.applicationType === "ar" &&
                            <AppButton
                                style={{ backgroundColor: colors.folderTopButton, padding: "10px", marginLeft: "8px", position: "absolute", bottom: "25px", right: "10px" }}
                                onClick={() => props.editCurrentProject(true)}
                                colored={true}
                                hovered={editButtonState.isHovered}
                                handleMouseEnter={editButtonState.handleMouseEnter}
                                handleMouseLeave={editButtonState.handleMouseLeave}
                                selected={editButtonState.isSelected}
                                handleMouseDown={editButtonState.handleMouseDown}
                                handleMouseUp={editButtonState.handleMouseUp}
                            >
                                <Edit size={20} style={{ stroke: editButtonState.isSelected ? colors.textBlack : editButtonState.isHovered ? colors.hovered : colors.subheading }} />
                            </AppButton>}
                    </div>
                </div>
            </Dialog>

            {windowsIGUPreferencesVisible && <ProjectWindowsIGUPreferencesDialog
                cancel={() => setWindowsIGUPreferencesVisible(false)}
                confirm={(selectedInsulationGlassUnitId, insulatedGlassUnitValuesPreferences) => confirmWindowIgu(selectedInsulationGlassUnitId, insulatedGlassUnitValuesPreferences)}
                insulatedGlassUnits={props.insulatedGlassUnits}
                insulatedGlassUnitValuesPreferences={props.project.insulatedGlassUnitValuesPreferences}
                selectedInsulationGlassUnitId={props.project.selectedInsulatedGlassUnit?.id} />}
        </div>}
    </>)
}

export default connect(function (store: State) {
    return {
        project: getCurrentProject(store),
        isEditMode: isCurrentProjectInEditMode(store),
        isBetaUser: areUnreleasedFeaturesAvailable(store),
        isBidManager: isBidManager(store),
        insulatedGlassUnits: getInsulatedGlassUnits(store),
        showInfo: isCurrentProjectInInfoMode(store),
        showSettings: isCurrentProjectInSettingsMode(store),
        modelsLoading: isModelLoading(store),
        engineeringModelsLoading: isEngineeringModelLoading(store)
    };
}, {
    updateProject, /*updateProjectLocation,*/saveCurrentProject,
    editCurrentProject, cancelEditingCurrentProject, currentProjectInfo, currentProjectSettings
})(StudioProjectPane);