export type Category = "Walls" | "Windows" | "Doors" | "Levels";

export class ViewerModelCategoriesMap {
    private readonly categoriesMap: Map<Category, number> = new Map();

    constructor() {
        this.categoriesMap.set("Walls", -2000011);
        this.categoriesMap.set("Windows", -2000014);
        this.categoriesMap.set("Levels", -2000240);
        this.categoriesMap.set("Doors", -2000023);
    }

    findCategoryId(category: Category): number | undefined {
        return this.categoriesMap.get(category);
    }
}

export class ViewerModelCollector {
    private readonly categoriesMap = new ViewerModelCategoriesMap();

    constructor(private readonly model: Autodesk.Viewing.Model) {
    }

    findElementsByCategory(category: Category): Promise<number[]> {
        const categoryId = this.categoriesMap.findCategoryId(category)?.toString();

        if (categoryId)
            return this.findElementsByCategoryId(categoryId);

        return new Promise((resolve, reject) => {
            this.model.search(category, x => resolve(x), () => reject(), ["_RC"], { searchHidden: true });
        });
    }

    private findElementsByCategoryId(categoryId: string): Promise<number[]> {
        return new Promise((resolve, reject) => this.model.search(categoryId, ids => resolve(ids), () => reject(), ["CategoryId"], { searchHidden: true }));
    }
}