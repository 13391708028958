import React from "react"
import { Oval } from "react-loader-spinner"

export const AppLoadingSpinner = () => {
    return <div style={containerStyle}>
        <Oval
            height={80}
            width={80}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />
    </div>
}

const containerStyle: React.CSSProperties = {
    margin: "auto",
    position: "absolute",
    left: "calc(50% - 40px)",
    top: "calc(50vh - 40px)"
}