import { connect } from "react-redux";
import NotificationsToast, { NotificationsToastList } from '@weave-design/notifications-toast';
import { getModelCycleFailureMessage, State } from "../reducers/mainReducer";
import { setModelCycleError } from "../actions/modelSaveCycleActions";
import { savingFailureNotificationToasListStyles } from "../assets/notificationStyles";

type Props = {
    message: string | null;
    dismissNotification: () => void;
}

const ModelSaveFailuresNotifications = ({ message, dismissNotification }: Props) => {
    return <NotificationsToastList placement="bottom" stylesheet={toastListStyleSheet}>
        {message && <NotificationsToast
            status="error"
            onDismiss={dismissNotification}
            key="model-save-cycle-error-message-notification">
            {message}
        </NotificationsToast>}
    </NotificationsToastList>
}

const mapStateToProps = (state: State) => ({
    message: getModelCycleFailureMessage(state)
});

const mapDispatchToProps = {
    dismissNotification: () => setModelCycleError(null)
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelSaveFailuresNotifications);

const toastListStyleSheet = (styles: any) => {
    if (styles.toastListWrapper)
        return { ...styles, toastListWrapper: { ...styles.toastListWrapper, ...savingFailureNotificationToasListStyles } };

    return styles;
}