export const getLeafNodes = (model: Autodesk.Viewing.Model, dbIds?: number[]): Promise<number[]> => {
    return new Promise((resolve, reject) => {
        try {
            const instanceTree = model.getInstanceTree()

            const nodeIds = dbIds || [instanceTree.getRootId()];

            const leafIds: number[] = []

            const getLeafNodeIdsRec = (id: number) => {
                let childCount = 0

                instanceTree.enumNodeChildren(id, (childId) => {
                    getLeafNodeIdsRec(childId)
                    ++childCount
                })

                if (childCount === 0) {
                    leafIds.push(id)
                }
            }

            nodeIds.forEach((dbId) => {
                getLeafNodeIdsRec(dbId)
            })

            return resolve(leafIds)
        } catch (ex) {
            return reject(ex)
        }
    })
}

export const getFragments = (model: Autodesk.Viewing.Model, dbId: number): number[] => {
    const fragments: number[] = [];

    const instanceTree = model.getInstanceTree();

    instanceTree.enumNodeFragments(dbId, x => {fragments.push(x)});

    return fragments;
}