import React, { Component } from 'react';
import ProgressBar from '@weave-design/progress-bar';
import "./processingPane.css";

export class ProcessingIndicator extends Component<{ message?: string }> {
    render() {
        return (<div className="processingProgressPane">
            <div className="processingProgressContent">
                <div className="processingProgressImage" style={{ backgroundImage: "url('/Assembly_icon.svg')" }} />
                <div className="processingProgressAction" style={{ fontWeight: "bold" }}>
                    <ProgressBar className="processingProgressBar" />
                </div>
            </div>
            <div className="processingProgressTimePrompt">This operation could take several minutes</div>
            {this.props.message && <div className="processingProgressStatusPane"><strong>Status</strong>: {this.props.message}</div>}
        </div>);
    }
}