//a temporary fix following the idea, taken from #2 of
//https://stackoverflow.com/questions/72890752/forge-viewer-7-71-throws-errors-when-removing-and-recreating-custom-meshes-in-mo/73051951#73051951
//see also https://github.com/DextallDevelopers/Dextall-Studio-Frontend/issues/46

export const makeFixOnObjectRecreation = (modelBuilder: Autodesk.Viewing.ModelBuilder) => {
    // @ts-ignore
    const scenes = modelBuilder.model.getIterator().getGeomScenes();

    for (const scene of scenes)
        scene.numAdded = 0;
}