import { addLog } from "./notificationActions";
import { showProcessingPane, showProcessingError } from "./processingActions";
import { fetchModels } from "./sourceRevitModelsActions";
import { getCurrentProject, getProjects } from "../reducers/projectsReducer";
import { getInsulatedGlassUnits } from '../reducers/mainReducer';
import { ActionDispatch, ActionGetState } from './common';
import { BasicItemsResponse, IProject } from '../components/project';
import { AxiosError } from 'axios';
import { IInsulatedGlassUnit } from '../components/forge/insulatedGlassUnit';
import { ApplicationType } from "../applicationType";
import repo from '../Repository';

type SetProjectsType = {
    type: "SET_PROJECTS";
    projects: IProject[];
}

type SetCurrentProjectType = {
    type: "CURRENT_PROJECT";
    project: IProject | null;
}

type CreateNewProjectType = {
    type: "CREATE_NEW_PROJECT",
    insulatedGlassUnit: IInsulatedGlassUnit
}

type EditCurrentProjectType = {
    type: "EDIT_CURRENT_PROJECT";
    mode: boolean;
}

type UpdateProjectType = {
    type: "UPDATE_PROJECT";
    project: IProject;
}

type CancelEditingCurrentProjectType = {
    type: "CANCEL_EDITING_PROJECT";
}

type CurrentProjectRemovedType = {
    type: "CURRENT_PROJECT_REMOVED"
}

type ShowInfoDiagramType = {
    type: "SHOW_INFO_DIAGRAM";
    mode: boolean;
}

type ShowSettingsDiagramType = {
    type: "SHOW_SETTINGS_DIAGRAM";
    mode: boolean;
}

type CreateNewProjectSuccessType = {
    type: "CREATE_NEW_PROJECT_SUCCESS";
    project: IProject;
}

export type ProjectActionType = SetProjectsType | SetCurrentProjectType | CreateNewProjectType | EditCurrentProjectType
    | UpdateProjectType | CancelEditingCurrentProjectType | CurrentProjectRemovedType | ShowInfoDiagramType | ShowSettingsDiagramType
    | CreateNewProjectSuccessType;

export const fetchProjects = (applicationType: ApplicationType) => async (dispatch: ActionDispatch) => {
    dispatch(addLog('Fetch projects invoked'));

    let response: BasicItemsResponse<IProject>;

    switch (applicationType) {
        case "en":
            response = await repo.loadEngineeringProjects();
            break;

        case "bid":
            response = await repo.loadBidProjects();
            break;

        default:
            response = await repo.loadProjects();
            break;
    }

    if (!response.isSuccess) {
        showProcessingError("Error", response.message);

        return;
    }

    dispatch(setProjects(response.items))

    if (response.items.length > 0) {
        dispatch(setCurrentProject(response.items[0]));
        dispatch(fetchModels(applicationType, response.items[0].id));
    }
}

export const setProjects = (projects: IProject[]): SetProjectsType => {
    return {
        type: "SET_PROJECTS",
        projects
    }
}

export const setCurrentProject = (project: IProject | null): SetCurrentProjectType => {
    return {
        type: "CURRENT_PROJECT",
        project
    }
}

export const createNewProject = () => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState();
    const insulatedGlassUnit = getInsulatedGlassUnits(state)[0];

    dispatch({
        type: "CREATE_NEW_PROJECT",
        project: {
            name: "",
            insulatedGlassUnit: insulatedGlassUnit,
            isNew: true
        }
    });

    dispatch(editCurrentProject(true));
    dispatch(currentProjectInfo(true));
};


export const editCurrentProject = (mode: boolean): EditCurrentProjectType => {
    return {
        type: "EDIT_CURRENT_PROJECT",
        mode
    }
}

export const currentProjectInfo = (mode: boolean): ShowInfoDiagramType => {
    return {
        type: "SHOW_INFO_DIAGRAM",
        mode
    };
};

export const currentProjectSettings = (mode: boolean): ShowSettingsDiagramType => {
    return {
        type: "SHOW_SETTINGS_DIAGRAM",
        mode
    };
};

export const updateProject = (project: IProject): UpdateProjectType => {
    return {
        type: "UPDATE_PROJECT",
        project
    }
}

export const cancelEditingCurrentProject = (applicationType: ApplicationType) => (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState().projects;

    const project = getCurrentProject(state);

    if (project?.isNew) {
        dispatch({ type: "REMOVE_CURRENT_PROJECT" });
        dispatch(currentProjectInfo(false));

        const targetProject = getProjects(state)[0];

        dispatch(setCurrentProject(targetProject));

        if (targetProject)
            dispatch(fetchModels(applicationType, targetProject.id));
    }

    dispatch({ type: "CANCEL_EDITING_PROJECT" });

    dispatch(editCurrentProject(false));
};


export const currentProjectRemoved = (): CurrentProjectRemovedType => {
    return {
        type: "CURRENT_PROJECT_REMOVED"
    }
}

export const updateProjectLocation = (lat: number, lng: number) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const project = getCurrentProject(getState().projects);

    if (!project?.isDirty)
        return;

    const geocodeResponse = await repo.findAddressByCoordinates(lat, lng);

    if (geocodeResponse.isSuccess) {
        dispatch(updateProject({ ...project, ...geocodeResponse.item }));
    } else
        console.error(`Failed to update project location: ${geocodeResponse.message}`);
}

export const saveCurrentProject = (applicationType: ApplicationType) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState().projects;
    const project = getCurrentProject(state);

    if (!project?.isDirty)
        return;

    dispatch(showProcessingPane(true, "Saving the project"));

    const isNewProject = project.isNew;

    const response = isNewProject ? await repo.createProject(project) : await repo.updateProject(project);

    dispatch(showProcessingPane(false));

    if (!response.isSuccess) {
        dispatch(showProcessingError("Saving project error", response.message));

        return;
    }

    if (project.isNew) {
        dispatch({
            type: "CREATE_NEW_PROJECT_SUCCESS",
            project: { ...project, isNew: false, isTemporary: false }
        });
    } else {
        dispatch({
            type: "UPDATE_PROJECT",
            project: { ...project, isDirty: false }
        });
    }

    dispatch(editCurrentProject(false));
    dispatch(fetchModels(applicationType, project.id));
}

export const removeCurrentProject = (applicationType: ApplicationType) => async (dispatch: ActionDispatch, getState: ActionGetState) => {
    const state = getState().projects;
    const project = getCurrentProject(state);

    if (!project)
        return;

    dispatch(showProcessingPane(true, "Removing the project"));

    let response;

    try {
        response = await repo.removeProject(project.id);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else {
            dispatch(showProcessingPane(false));
            dispatch(showProcessingError("Removing project error", "Something went wrong during removing the project"));

            return;
        }
    }

    dispatch(showProcessingPane(false));

    if (!response.isSuccess) {
        dispatch(showProcessingError("Saving project error", response.message));

        return;
    }

    dispatch(currentProjectRemoved());

    const targetProject = getProjects(state).find(x => x.id !== project.id) || null;

    dispatch(setCurrentProject(targetProject));

    if (targetProject)
        dispatch(fetchModels(applicationType, targetProject.id));
}