// use fixed version of the viewer to avoid usage of untested viewer version
import { DisplayCurrencies } from "./modelAnalytics";

const viewerVersion = '7.101.0';

export const viewerCss = `https://developer.api.autodesk.com/modelderivative/v2/viewers/${viewerVersion}/style.min.css`;
export const viewerJs = `https://developer.api.autodesk.com/modelderivative/v2/viewers/${viewerVersion}/viewer3D.min.js`;
export const appRootId = 'root';
export const modalsContainerId = 'model-editor-viewer-modals-container';

export const roundToMillions = (x: number, currency: DisplayCurrencies, currencyCoef: number) => {
    const sign = currency === DisplayCurrencies.Eur ? "€" : "$";
    const coef = currency === DisplayCurrencies.Eur ? 1 : currencyCoef;

    const priceValue = Math.abs(Number(x)) >= 1.0e+9
        ? (Math.abs(Number(x) * coef) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(x)) >= 1.0e+6
            ? (Math.abs(Number(x) * coef) / 1.0e+6).toFixed(2) + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(x)) >= 1.0e+3
                ? (Math.abs(Number(x) * coef) / 1.0e+3).toFixed(2) + "K"
                : Math.abs(Number(x) * coef).toFixed(2);

    return `${priceValue} ${sign}`;
}

export const roundToFixes = (x: number, currency: DisplayCurrencies, currencyCoef: number) => {
    const sign = currency === DisplayCurrencies.Eur ? "€" : "$";
    const coef = currency === DisplayCurrencies.Eur ? 1 : currencyCoef;
    const priceValue = (x * coef).toFixed(2);
    return `${priceValue} ${sign}`;
}

export const tokenStorageName = "dextallstudio.portaluser.token.v2";