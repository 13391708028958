import { useState } from 'react';
import { connect } from 'react-redux';
import { styles } from "../assets/studioPaneStyles";
import { colors, card } from "../config/colors";
import { getCurrentProject, getApplicationUserName, State } from "../reducers/mainReducer";
import { currentProjectInfo, currentProjectSettings, removeCurrentProject } from "../actions/projectsActions";
import { TextBody2, TextH2 } from "../assets/text/index";
import { AppButton } from "./appButton";
import { ChevronRight, Info, Settings, Trash } from "react-feather";
import { Project } from '../reducers/projectsReducer';
import { ApplicationType } from '../applicationType';
import { useButtonState } from './useButtonState';
import { ConfirmDialog } from './confirmDialog';

interface IComponentProps {
    project: Project | null;
    userName: string;
    applicationType: ApplicationType;

    currentProjectInfo: (mode: boolean) => void;
    currentProjectSettings: (mode: boolean) => void;
    removeCurrentProject: (applicationType: ApplicationType) => void;
}

export const ProjectToolbarControls = (props: IComponentProps) => {
    const infoButtonState = useButtonState();
    const settingsButtonState = useButtonState();
    const trashButtonState = useButtonState();

    const [confirmDeleteProjectVisible, setConfirmDeleteProjectVisible] = useState(false);

    return (<div style={styles.folderTop}>
        <div style={styles.topTextContainer}>
            <AppButton
                style={{ backgroundColor: colors.folderTopButton, padding: "8px", marginRight: 5 }}
                onClick={() => props.currentProjectInfo(true)}
                colored={true}
                hovered={infoButtonState.isHovered}
                handleMouseEnter={infoButtonState.handleMouseEnter}
                handleMouseLeave={infoButtonState.handleMouseLeave}
                selected={infoButtonState.isSelected}
                handleMouseDown={infoButtonState.handleMouseDown}
                handleMouseUp={infoButtonState.handleMouseUp}
                prompt="Project Information"
                hidden={!props.project}
            >
                <Info size={20} style={{ stroke: infoButtonState.isSelected ? colors.textBlack : infoButtonState.isHovered ? colors.hovered : colors.subheading }} />
            </AppButton>
            <AppButton
                style={{ backgroundColor: colors.folderTopButton, padding: "8px", marginLeft: 5, marginRight: 5 }}
                onClick={() => props.currentProjectSettings(true)}
                colored={true}
                hovered={settingsButtonState.isHovered}
                handleMouseEnter={settingsButtonState.handleMouseEnter}
                handleMouseLeave={settingsButtonState.handleMouseLeave}
                selected={settingsButtonState.isSelected}
                handleMouseDown={settingsButtonState.handleMouseDown}
                handleMouseUp={settingsButtonState.handleMouseUp}
                prompt="Project Settings"
                hidden={!props.project}
            >
                <Settings size={20} style={{ stroke: settingsButtonState.isSelected ? colors.textBlack : settingsButtonState.isHovered ? colors.hovered : colors.subheading }} />
            </AppButton>
            {props.applicationType === "ar" &&
                <AppButton
                    style={{ backgroundColor: card.versionProject, padding: "8px", marginLeft: 5 }}
                    onClick={() => setConfirmDeleteProjectVisible(true)}
                    colored={true}
                    hovered={trashButtonState.isHovered}
                    handleMouseEnter={trashButtonState.handleMouseEnter}
                    handleMouseLeave={trashButtonState.handleMouseLeave}
                    selected={trashButtonState.isSelected}
                    handleMouseDown={trashButtonState.handleMouseDown}
                    handleMouseUp={trashButtonState.handleMouseUp}
                    prompt="Delete Project"
                    hidden={!props.project}
                >
                    <Trash size={20} style={{ stroke: trashButtonState.isSelected ? colors.textBlack : trashButtonState.isHovered ? colors.hovered : colors.subheading }} />
                </AppButton>}
            <ConfirmDialog
                isOpened={confirmDeleteProjectVisible}
                title="Remove project"
                confirmButtonTitle="Delete"
                deleteButtonClassName="red"
                windowStyles={{ top: "206px" }}
                onCancel={() => setConfirmDeleteProjectVisible(false)}
                onConfirm={() => {
                    setConfirmDeleteProjectVisible(false);
                    props.removeCurrentProject(props.applicationType);
                }}>
                Are you sure you want to delete project <b>"{props.project?.name}"</b>?
            </ConfirmDialog>

            {props.project && <div style={styles.topTextContainer}>
                <div style={{ flexDirection: "column" }}>
                    <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>User</TextBody2>
                    <TextH2 style={{ fontSize: 17 }}>{props.applicationType !== "ar" ? props.project?.userName : props.userName}</TextH2>
                </div>
                <ChevronRight style={{ marginLeft: 8, marginRight: 8 }} color={colors.subheading} size="20" />
                <div style={{ flexDirection: "column" }}>
                    <TextBody2 style={{ color: colors.subheading, lineHeight: "17px" }}>Project</TextBody2>
                    <TextH2 style={{ fontSize: 17 }}>{props.project?.name}</TextH2>
                </div>
            </div>}
        </div>
    </div>)
}

export default connect(function (store: State) {
    return {
        project: getCurrentProject(store),
        userName: getApplicationUserName(store)
    };
}, { currentProjectInfo, currentProjectSettings, removeCurrentProject })(ProjectToolbarControls);