export const setViewerToolsModality = (viewer: Autodesk.Viewing.GuiViewer3D, toolNames: string[]) => {
    for (const toolName of toolNames) {
        const modality: { [key: string]: boolean } = {};

        for (const modalTool of toolNames)
            if (modalTool !== toolName)
                modality[modalTool] = false;

        viewer.toolController.setToolModality(toolName, modality);
    }
}