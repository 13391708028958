import { appRootId } from "./components/shared";

export const getLocalApplicationMetadata = () => {
    const appRoot = document.getElementById(appRootId)!;

    const environment = appRoot.attributes.getNamedItem("app_env")?.value;

    const version = appRoot.attributes.getNamedItem("app_version")?.value;

    return { environment, version };
}